<template>   
    <b-card-text>
        <b-row class="mb-1">
            <b-col sm="12">
                <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Punto de Venta">
                    <i class="fa fa-plus"></i> Agregar
                </b-button>
            </b-col>
        </b-row>

                        
        <b-row class="mb-2">
            <b-col>
                <b-table class="mb-0"
                        responsive="sm"
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="false"
                        :items="table.items"
                        :fields="table.fields"
                        :filter="table.filter"
                        :current-page="table.currentPage"
                        :per-page="table.perPage"
                        selectable
                        select-mode="single"
                        @row-selected="onRowSelected"   
                        v-if="table.items.length">                
                
                    <template v-slot:row-details="row">
                        <b-card>
                            <b-row>
                                <b-col>
                                    <b-row>
                                        <b-col md="12">
                                            <b-table class="mb-0"
                                                    responsive="sm"
                                                    head-variant="dark"
                                                    :hover="true"
                                                    :small="true"
                                                    :fixed="false"
                                                    :items="tableSub.items"
                                                    :fields="tableSub.fields"                            
                                                    v-if="tableSub.items.length">                                                                                     

                                            <template v-slot:cell(comprobante)="data">
                                                <b>{{data.item.type_voucher.name}}</b>
                                            </template>  

                                            <template v-slot:cell(numero)="data">
                                                <b>{{data.item.number}}</b>
                                            </template>  
                                            
                                            <template v-slot:cell(afip)="data">
                                              <div v-if="showLastVoucher(data.item) == 'S/M'">
                                                <b title="Sin movimientos">0</b>
                                              </div>
                                              <div v-else>
                                                <b v-if="showLastVoucher(data.item)" v-html="showLastVoucher(data.item)"></b>
                                                <span v-else title="Consultar Numeración">Consultar</span>
                                              </div>
                                            </template>  

                                            <template v-slot:cell(f_action)="data">                       
                                                <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                                                <b-dropdown-item @click="getLastVoucher(data.item)" v-if="row.item.status">
                                                  <b-icon icon="info-circle-fill"></b-icon> Último Comprobante
                                                </b-dropdown-item>                                                  
                                                <b-dropdown-header>Acciones</b-dropdown-header>
                                                <b-dropdown-item @click="editNumber(data.item)">
                                                    <i class="fa fa-pencil" style="color:blue"></i> Editar
                                                </b-dropdown-item>
                                                </b-dropdown>
                                            </template>

                                            </b-table>
                                            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </b-card>
                    </template>

                    <template v-slot:cell(id)="data">
                        <b>{{ data.item.id }}</b>
                    </template>

                    <template v-slot:cell(business)="data">
                        {{ data.item.business.name }}
                    </template>

                    <template v-slot:cell(point_sale)="data">
                        <h5>
                            <b-badge variant="secondary" v-if="data.item.point_sale">
                                {{ data.item.point_sale.toString().padStart(4,'0') }}
                            </b-badge>                        
                        </h5>
                    </template>

                    <template v-slot:cell(name)="data">
                        {{ data.item.name }}
                    </template>

                    <template v-slot:cell(status)="data">
                      <div  v-if="data.item.nd">
                        <b-badge variant="dark">
                          NO INFORMADO
                        </b-badge>
                      </div>
                      <div v-else>
                        <div v-if="data.item.certificate && data.item.private_key && data.item.csr">
                          <b-badge variant="info" v-if="data.item.afip_production">
                              PRODUCCION
                          </b-badge>    
                          <b-badge variant="warning" v-if="!data.item.afip_production">
                              HOMOLOGACION
                          </b-badge>                
                        </div>
                        <span v-else class="text-dark">
                          <i>Configurando</i>
                        </span> 
                      </div>
                    </template>
                    
                    <template v-slot:cell(certificate)="data">
                      <div v-if="!data.item.nd">
                        <b v-if="data.item.certificate && data.item.private_key && data.item.csr" class="text-primary">
                          Instalado                
                        </b>                
                        <span v-else class="text-dark">
                          <i>Configurando</i>
                        </span>                                            
                      </div>
                    </template>

                    <template v-slot:cell(conection)="data">
                        <div v-if="!data.item.nd">
                          <b-icon v-if="data.item.status" icon="check-circle-fill" variant="success" font-scale="1.5" title="Conectado a los servidores de AFIP"></b-icon>
                          <b-icon v-else icon="exclamation-circle-fill" variant="danger" font-scale="1.5" title="Problemas de conexión"></b-icon>
                        </div>
                    </template>

                    <template v-slot:cell(f_action)="data">
                        <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">      
                            <b-dropdown-item @click="connectAFIP(data.item)" v-if="!data.item.nd && data.item.certificate && data.item.private_key && data.item.csr">
                              <b-icon icon="plug"></b-icon> Conectar AFIP
                            </b-dropdown-item>         

                            <b-dropdown-header>Acciones</b-dropdown-header>                                                                                       
                            <b-dropdown-item @click="edit(data.item)">
                                <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                            </b-dropdown-item>
                            <b-dropdown-item @click="remove(data.item)">
                                <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>
                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
            </b-col>
        </b-row>            
            
        <b-row>
            <b-col>
                <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
                    <i class="fa fa-angle-double-left"></i>
                    Volver
                </b-button>                        
            </b-col>

            <b-col>
                <nav>
                <b-pagination class="pull-right mb-0"
                                size="sm"
                                pills=""
                                :total-rows="getRowCount(table.items)"
                                :per-page="table.perPage"
                                v-model="table.currentPage" />
                </nav>
            </b-col> 
        </b-row>            

        <!-- ########################### -->
        <!-- #####     MODALES     ##### -->
        <!-- ########################### -->

        <b-modal v-model="modal.form.active"
                header-bg-variant="dark"
                size="lg"
                header-text-variant="white">

            <div slot="modal-header">
                {{this.modal.form.title}}
            </div>

            <b-tabs active-nav-item-class="font-weight-bold text-uppercase text-dark">
              <b-tab title="General">
                <b-row>         
                    <b-col md="12">
                      <FindObject render="search"
                                  type="my-business" 
                                  @select-object="loadBusiness($event)" 
                                  :valueID="crud.form.business_id"
                                  :showAllRegister="true"/>                       
                    </b-col>                     

                    <b-col md="3">
                        <b-form-group label="Punto de venta">
                          <b-form-input type="number"
                                          size="sm"
                                          v-model="crud.form.point_sale"
                                          required>
                          </b-form-input>
                        </b-form-group>
                    </b-col> 

                    <b-col md="9">
                        <b-form-group label="Referencia">
                          <b-form-input type="text"
                                          size="sm"
                                          v-model="crud.form.name"
                                          required>
                          </b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col md="12" class="mb-3">       
                      <hr>
                    </b-col>
                    <b-col md="12" class="mb-3">       
                      <b-form-group label="Comprobantes No Informados" description="Si la opción se activa, los movimientos realizados, no se informan al AFIP.">         
                        <b-form-checkbox v-model="crud.form.nd" 
                                          switch 
                                          size="sm"                                      
                                          @input="changeCertificate()">
                          No Informados
                        </b-form-checkbox>                      
                      </b-form-group>      
                    </b-col>                       
                </b-row>
              </b-tab>
              <b-tab title="AFIP" v-if="!crud.form.nd">

                <b-tabs pills card>
                  <b-tab title="Configuración" active>
                    <b-tabs pills card vertical>
                      <b-tab title="CSR" active>
                        <b-card-text>
                          <b-form-textarea                                
                              v-model="crud.form.csr"
                              size="sm"
                              placeholder="Ingresar el CSR"
                              rows="10"
                              :readonly="true">
                          </b-form-textarea>  
                        </b-card-text>           
                        <b-link href="javascript:void(0);" @click="createCSR(crud.form.id)">
                          <b-icon icon="terminal"></b-icon> Generar CSR
                        </b-link>         
                        <b-link href="javascript:void(0);" @click="downloadCSR(crud.form.csr)" class="pull-right text-success" v-if="crud.form.csr">
                          <b-icon icon="cloud-arrow-down"></b-icon> Descargar CSR
                        </b-link>         
                      </b-tab>
                      <b-tab title="KEY">
                        <b-card-text>
                          <b-form-textarea                                
                              v-model="crud.form.private_key"
                              size="sm"
                              placeholder="Ingresar la Clave Privada"
                              rows="10"
                              :readonly="true">
                          </b-form-textarea>
                        </b-card-text>
                      </b-tab>                      
                      <b-tab title="CRT">
                        <b-card-text>                    
                            <b-form-textarea                                
                                v-model="crud.form.certificate"
                                size="sm"
                                placeholder="Ingresar el Certificado"
                                rows="10"
                                :readonly="Boolean(crud.form.status)">
                            </b-form-textarea>                 
                        </b-card-text>                    
                      </b-tab>
                    </b-tabs>
                  
                    <b-row> 
                        <b-col md="12">
                          <hr>
                        </b-col>

                        <b-col md="6" class="mb-3">
                            <b-form-group label="Tipo de Venta">
                                <b-form-select v-model="crud.form.type_sale" :options="arr.select.type_sale"></b-form-select>
                            </b-form-group>
                        </b-col>
                        
                        <b-col md="6" class="mb-3">
                          <b-form-group label="¿Listo para producción?" description="Si la opción se encuentra desactivada, todas las operaciones pasan por los servidores de Homologación (modo prueba)">
                            <b-form-checkbox v-model="crud.form.afip_production" 
                                              switch 
                                              size="sm">
                                Producción
                            </b-form-checkbox>                      
                          </b-form-group>      
                        </b-col>
                    </b-row>
                  </b-tab>
                  <b-tab title="Ayuda">
                    <b-tabs pills card vertical>
                      <b-tab title="Homologación" active>
                        <b-card-text>
                          <h3>Homologación (para pruebas)</h3>
                          <p>
                            <ul>
                              <li>
                                Acceder a <a href="https://auth.afip.gob.ar/" target="_blank">AFIP</a> con clave fiscal
                              </li>
                              <li>
                                Acceder al item de menu <b>Administrador de relaciones</b> y luego ir a <b>Adherir servicio</b>                            
                              </li>
                              <li>
                                Luego acceder en el item <b>AFIP</b> -> <b>Servicios Interactivos</b> -> <b>WSASS</b>
                              </li>
                              <li>
                                Seguir los pasos hasta confirmar la relación
                              </li>              
                              <li>
                                Generar el <b>CSR</b>, desde el <b>sistema</b>
                              </li>                                    
                              <li>
                                Ingresar en <b>AFIP</b> e ir al nuevo servicio agregado <b>WSASS</b>
                              </li>                
                              <li>
                                Vamos a agregar un <b>Nuevo Certificado</b>, le agregamos un <b>nombre simbólico</b>, en el campo <b>"PKCS#10"</b> 
                                pegar el contenido de nuestro <b>CSR</b> y click en el botón <b>Obtener Certificado</b>
                              </li>
                              <li>
                                El contenido que se carga en el campo <b>Resultado</b>, copiarlo y pegarlo en el <b>sistema</b>, en el campo <b>CRT</b>
                              </li>
                              <li>
                                Por último, ir al menú <b>Crear Autorización de Servicio</b>, seleccionar el <b>alias</b> creado anteriormente y 
                                seleccionar la opción <b>"wsfe - Facturación Electrónica"</b> de la lista y click en el botón 
                                <b>Crear autorización de accesos</b>
                              </li>
                            </ul>
                          </p>     
                        </b-card-text>                     
                      </b-tab>
                      <b-tab title="Producción">
                        <b-card-text>
                          <h3>Producción</h3>
                          <p>
                            <ul>
                              <li>
                                Acceder a <a href="https://auth.afip.gob.ar/" target="_blank">AFIP</a> con clave fiscal
                              </li>
                              <li>
                                Acceder al item de menu <b>Administrador de relaciones</b> y luego ir a <b>Adherir servicio</b>                            
                              </li>
                              <li>
                                Luego acceder en el item <b>AFIP</b> -> <b>Servicios Interactivos</b> -> <b>Administración de Certificados Digitales</b>
                              </li>
                              <li>
                                Seguir los pasos hasta confirmar la relación
                              </li>  
                              <li>
                                Generar el <b>CSR</b>, desde el <b>sistema</b> y descargarlo
                              </li>                                    
                              <li>
                                Ingresar en <b>AFIP</b> e ir al nuevo servicio agregado <b>Administración de Certificados Digitales</b>
                              </li>                                 
                              <li>
                                Click en <b>Agregar alias</b>, ingresamos un nombre y subimos el archivo <b>CSR</b>                                
                              </li>
                              <li>
                                Luego ingresar en el link <b>"Ver"</b> correspondiente al alias creado. Nos va a mostrar la opción de descargar el CRT
                              </li>
                              <li>
                                Abrimos el <b>CRT</b> con un block de notas, el contenido, lo pegamos en el campo <b>CRT del sistema</b>
                              </li>     
                              <li>
                                Acceder al item de menu <b>Administrador de relaciones</b> y luego ir a <b>Adherir servicio</b>                            
                              </li>
                              <li>
                                Luego acceder en el item <b>AFIP</b> -> <b>WebServices</b> -> <b>Factura Electrónica</b>
                              </li>                      
                              <li>
                                Asociamos el alias, como <b>"Representante"</b> del servicio
                              </li>                                                    
                            </ul>
                          </p>
                        </b-card-text>                        
                      </b-tab>
                    </b-tabs>                           
                  </b-tab>
                </b-tabs>
              </b-tab>
              <b-tab title="Opciones">
                <b-row>
                  <b-col md="6" v-if="showDeposit">
                    <FindObject render="search"
                                description="Depósito por defecto para descontar productos cuando se utilice este punto de venta. Si queda vacío, queda sin efecto"
                                type="deposits"                            
                                @select-object="loadDeposit($event)" 
                                :valueID="crud.form.deposit_id"
                                :showAllRegister="true"/>                         
                  </b-col>
                  <b-col md="6" v-if="showFinance">
                    <FindObject render="search"
                                description="Caja por defecto para recibir o pagar cuando se utilice este punto de venta. Si queda vacío, queda sin efecto"
                                type="cash" 
                                @select-object="loadCash($event)" 
                                :valueID="crud.form.cash_id"
                                :showAllRegister="true"
                                :key="crud.form.cash_id"
                                :where="conditionCashND"/>
                  </b-col>   
                  <b-col md="12"  v-if="showBudget">               
                    <b-form-group label="Firma" description="Se utiliza para firmar los presupuestos emitidos desde este punto de venta.">
                      <vue-editor class="display-signature-ql-editor" v-model="crud.form.signature"></vue-editor>              
                    </b-form-group>                    
                  </b-col>
                </b-row>
              </b-tab>
            </b-tabs>
            <div slot="modal-footer">
                <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
                <b-button variant="dark" @click="save()">Guardar</b-button>          
            </div>
        </b-modal>

        <!-- NUMERATION -->
        <b-modal v-model="modal.formNumeration.active"              
                 header-bg-variant="dark"
                 header-text-variant="white">

            <div slot="modal-header">
                {{this.modal.formNumeration.title}}
            </div>
                    
            <b-row>
                <b-col md="12">
                    <b-form-group label="Número">
                        <b-form-input type="number"
                                      min="0"
                                      size="sm"
                                      v-model="crud.formNumeration.number"
                                      required>
                        </b-form-input>
                    </b-form-group> 
                </b-col>
            </b-row>  
            
            <div slot="modal-footer">
                <b-button variant="outline-secondary" class="mr-1" @click="modal.formNumeration.active=false">Cancelar</b-button>
                <b-button variant="dark" @click="saveNumber()">Guardar</b-button>          
            </div>
        </b-modal> 

    </b-card-text>                                
</template>

<script>
    import serviceAPI from './../services'
    import Error from '@/handler/error'
    import Profiles from '@/config/profiles'
    import Session from '@/handler/session'  
    import Modules from '@/config/modules'
    import Helper from '@/handler/helper' 
    import FindObject from '@/components/inc/find/findObject'       
    import { VueEditor } from "vue2-editor";

    export default {
        components: {
          FindObject,
          VueEditor,
        },       
        data: () => {
          return {      
            table : {
              items: [],
              fields: [
                {key: 'id', label: 'ID', sortable: true, class:"align-middle"},                                        
                {key: 'business', label: 'Empresa', class:"align-middle"}, 
                {key: 'point_sale', label: 'Punto de Venta', class:"align-middle text-center"},           
                {key: 'name', label: 'Nombre', class:"align-middle"},                      
                {key: 'status', label: 'Estado', class:"align-middle text-center"},      
                {key: 'certificate', label: 'Certificado', class:"align-middle text-center"},
                {key: 'conection', label: 'Conexión', class:"align-middle text-center"},                
                {key: 'f_action', label:'', class:"align-middle text-right"},
              ],
              currentPage: 1,
              perPage: 50,
            },
            tableSub : {
              items: [],
              fields: [
                {key: 'comprobante', label: 'Comprobante', class:'align-middle'},
                {key: 'numero', label: 'Número', class:'align-middle text-center'},
                {key: 'afip', label: 'Último Nº AFIP', class:'align-middle text-center'},
                {key: 'f_action', label:'', class:'align-middle'},
              ],
              filter: null,
              mostrarFiltros: false,
              currentPage: 1,
              perPage: 50,
            },
            arr: {
              numeration: [],
              select: {
                type_sale: [
                  { text: 'Bienes', value: 'bienes' },
                  { text: 'Servicios', value: 'servicios' },
                  { text: 'Bienes y Servicios', value: 'bienes_servicios' },
                ],
              }
            },
            crud: {
              form: {
                id: 0,
                business: null,
                business_id: 0,                
                point_sale: 0,
                name: '',
                nd: false,
                certificate: '',
                pivate_key: '',
                csr: '',
                afip_production: false,
                type_sale: 'bienes',
                cash_id: 0,
                cash: null,
                deposit_id: 0,
                deposit: null,
                status: false,
                signature: '',
              },
              formNumeration: {
                points_sales_id: 0,
                types_vouchers_id: 0,
                number: 0,
              },
              formLastNumberAFIP: [],
            },
            modal: {
              form: {
                active: false,
                title: ''
              },
              formNumeration: {
                active: false,
                title: ''
              },
            },
          }
        },
        computed: {
          // WHERE SELECT
          conditionCashND(){        
            return [{field: 'nd', condition: this.crud.form.nd}];
          }, 

          // MODULES
          showDeposit() {
            var status = false
            var modules = Session.getSession().auth.user.permissions.modules        
            modules.forEach(element => {
              if(element.id == Modules.DEPOSITOS) {                             
                status = true                       
              }
            })        
            return status
          },          
          showFinance() {
            var status = false
            var modules = Session.getSession().auth.user.permissions.modules        
            modules.forEach(element => {
              if(element.id == Modules.FINANZAS) {                             
                status = true                       
              }
            })        
            return status
          }, 
          showBudget() {
            var status = false
            var modules = Session.getSession().auth.user.permissions.modules        
            modules.forEach(element => {
              if(element.id == Modules.PRESUPUESTOS) {                             
                status = true                       
              }
            })        
            return status
          },                              
        },
        mounted () {
          this.show()
        },
        methods: {
          getRowCount (items) {
            return items.length
          },
          onRowSelected(item) {      
            this.arr.numeration.forEach((element, index) => {
              if(item.length){
                if(element.id == item[0].id) {
                  this.openDetail(index)
                }
              }
            }) 
          },     
          openDetail(index) {
            this.table.rowSelected = index
            this.show()
          }, 
          show() {        
            var result = serviceAPI.obtenerPuntosVentas()

            result.then((response) => {
              var data = response.data
              this.table.items = data
              this.arr.numeration = data

              if(this.table.rowSelected!=null) {
                this.table.items[this.table.rowSelected]._showDetails = true

                if(this.table.items[this.table.rowSelected].numeration) {
                  this.tableSub.items = this.table.items[this.table.rowSelected].numeration
                }
              }
            })
            .catch(error => {
              this.$awn.alert(Error.showError(error))
            });  
          },
          add() {
            this.crud.form.id = 0
            this.crud.form.business = null
            this.crud.form.business_id = 0          
            this.crud.form.point_sale = 0
            this.crud.form.name = ''
            this.crud.form.nd = false
            this.crud.form.certificate = ''
            this.crud.form.private_key = ''
            this.crud.form.csr = ''
            this.crud.form.afip_production = false
            this.crud.form.type_sale = 'bienes'
            this.crud.form.cash_id = 0
            this.crud.form.cash = null
            this.crud.form.deposit_id = 0
            this.crud.form.deposit = null
            this.crud.form.status = false
            this.crud.form.signature = ''

            this.modal.form.title = "Nuevo Punto de Venta"
            this.modal.form.active = true
          },
          edit(item) {                
            this.crud.form.id = item.id
            this.crud.form.business = item.business
            this.crud.form.business_id = item.business_id
            this.crud.form.point_sale = item.point_sale   
            this.crud.form.name = item.name
            this.crud.form.nd = item.nd
            this.crud.form.certificate = item.certificate
            this.crud.form.private_key = item.private_key
            this.crud.form.csr = item.csr
            this.crud.form.afip_production = item.afip_production
            this.crud.form.type_sale = item.type_sale
            this.crud.form.cash_id = item.cash_id
            this.crud.form.cash = item.cash
            this.crud.form.deposit_id = item.deposit_id
            this.crud.form.deposit = item.deposit
            this.crud.form.status = item.status
            this.crud.form.signature = item.signature

            this.modal.form.title = "Editar Punto de Venta"
            this.modal.form.active = true
          },
          remove(item) {
            this.crud.form.id = item.id

            this.$bvModal.msgBoxConfirm('¿Desea borrar el item (' + this.crud.form.id + ') - '+ item.name + ' ['+ item.point_sale +']?', {
              title: 'Borrar Punto de Venta',
              size: 'lg',
              buttonSize: 'lg',
              okVariant: 'danger',
              okTitle: 'SI',
              cancelTitle: 'NO',
              footerClass: 'p-2',
              headerBgVariant: 'danger',
              headerTextVariant: 'white',
              hideHeaderClose: false,
              centered: true
            })
            .then(value => {
              if (value) {
                let loader = this.$loading.show();
                var result = serviceAPI.eliminarPuntosVentas(this.crud.form.id);

                result.then((response) => {      
                  this.modal.form.active = false        
                  loader.hide()
                  this.show()
                  this.$awn.success("Registro eliminado");
                })
                .catch(error => {
                  loader.hide()
                  this.$awn.alert(Error.showError(error));
                })
              }
            })
            .catch(error => {
              this.$awn.alert(Error.showError(error));
            })
          },
          save() {                
            if(this.crud.form.point_sale) {
              if(this.crud.form.point_sale <= 0) {                    
                this.$awn.alert("El punto de venta es obligatorio")
                return false
              }
            }

            let loader = this.$loading.show();
            if (this.crud.form.id) {
              var result = serviceAPI.editarPuntosVentas(this.crud.form);
            } else {
              var result = serviceAPI.agregarPuntosVentas(this.crud.form);
            }

            result.then((response) => {
              this.modal.form.active = false
              loader.hide()
              this.show()
              this.$awn.success("Datos guardados con éxito");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          },
          changeCertificate() {              
            this.crud.form.certificate = ''

            this.crud.form.cash = null
            this.crud.form.cash_id = 0
          },
          editNumber(item) {
            this.crud.formNumeration.points_sales_id = item.points_sales_id        
            this.crud.formNumeration.types_vouchers_id = item.types_vouchers_id
            this.crud.formNumeration.number = item.number

            this.modal.formNumeration.title = 'Editar numeración de "' + item.type_voucher.name + '"'
            this.modal.formNumeration.active = true
          },
          saveNumber() {
            this.crud.formNumeration.number = parseInt(this.crud.formNumeration.number)

            let loader = this.$loading.show();

            var result = serviceAPI.editarNumeracion(this.crud.formNumeration);                

            result.then((response) => {
              this.modal.formNumeration.active = false
              loader.hide()
              this.show()
              this.$awn.success("Datos guardados con éxito");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          },
          getLastVoucher(item) {            
            let loader = this.$loading.show();

            var result = serviceAPI.getLastVoucherAFIP({
              'point_sale_id': item.points_sales_id,
              'type_voucher_id': item.types_vouchers_id
            });  
            
            result.then((response) => {
              loader.hide()
              
              this.crud.formLastNumberAFIP.push({
                'point_sale': item.points_sales_id,
                'type_voucher': item.types_vouchers_id,
                'number_afip': response.data
              })
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })            
          },
          showLastVoucher(item) {            
            var valid = false
            var number = 0
            this.crud.formLastNumberAFIP.forEach(element => {
              if(element.type_voucher == item.types_vouchers_id && element.point_sale == item.points_sales_id) {
                if(number < element.number_afip) {
                  number = element.number_afip
                }

                valid = true
              }
            });

            if(valid) {
              if(item.number == number) {
                item._rowVariant = 'success'
              } else {
                item._rowVariant = 'danger'
              }

              if(number == 0) {
                return 'S/M'
              } else {
                return number
              }              
            } else {              
              return number
            }            
          },

          loadCash (object) {        
            if(object){
              this.crud.form.cash = object
              this.crud.form.cash_id = object.id        
            } else {
              this.crud.form.cash = null
              this.crud.form.cash_id = 0
            }
          },     
          loadDeposit (object) {        
            if(object){
              this.crud.form.deposit = object
              this.crud.form.deposit_id = object.id                   
            } else {
              this.crud.form.deposit = null
              this.crud.form.deposit_id = 0
            }
          },                
          loadBusiness (object) {
            if(object){
              this.crud.form.business = object
              this.crud.form.business_id = object.id             
            } else {
              this.crud.form.business = null
              this.crud.form.business_id = 0
            }
          },          

          // CSR
          createCSR(id) {
            var msj = '¿Desea generar el CSR para el punto de venta?'
            var variant = 'success'

            if(this.crud.form.private_key && this.crud.form.csr) {
              msj = 'Si se vuelve a generar el CSR, tendrá que vovler a vincularlo en AFIP y descargar el nuevo CRT. ¿Desea continuar?'
              variant = 'danger'
            } 

            this.$bvModal.msgBoxConfirm(msj, {
              title: 'Generación de CSR',
              size: 'lg',
              buttonSize: 'lg',
              okVariant: variant,
              okTitle: 'SI',
              cancelTitle: 'NO',
              footerClass: 'p-2',
              headerBgVariant: variant,
              headerTextVariant: 'white',
              hideHeaderClose: false,
              centered: true
            })
            .then(value => {
              if (value) {            
                let loader = this.$loading.show();
                var result = serviceAPI.createCSR({id: id});

                result.then((response) => {    
                  loader.hide()

                  var data = response.data                                    
                  this.crud.form.private_key = data.private_key
                  this.crud.form.csr = data.csr
                  this.crud.form.status = data.status

                  this.show()
                  this.$awn.success("CSR creado con éxito");
                })
                .catch(error => {
                  loader.hide()
                  this.$awn.alert(Error.showError(error));
                })
              }
            })
            .catch(error => {
              this.$awn.alert(Error.showError(error));
            })                
          },
          downloadCSR(csr) {
            var element = document.createElement('a');
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csr));
            element.setAttribute('download', "afip.csr");

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
          },

          // AFIP
          connectAFIP(item) {
            let loader = this.$loading.show();
            var result = serviceAPI.connectAFIP(item);

            result.then((response) => {    
              loader.hide()

              var data = response.data         
              
              if(data)
                this.$awn.success("Conección realizada con éxito");
              else
                this.$awn.alert("Fallo en la conexión con AFIP");

              this.show()
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        } 
    }
</script>
<style>
  .display-signature-ql-editor .ql-editor {
    min-height: 200px !important;
  }  
</style>